import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueParticles from 'vue-particles'
import VueScrollTo from 'vue-scrollto'

Vue.prototype.$baseURL = 'https://weatherapi.blitztechnology.tech'
Vue.prototype.$fixedBalai = 3

Vue.use(VueScrollTo)
Vue.use(VueParticles)
Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)
Vue.config.productionTip = false




new Vue({
    router,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')