<template>
    <div>
        <div class="background-div">
            <vue-particles class="particle-container"></vue-particles>
        </div>
        <section class="hero-banner--sm foreground-div pro-div">
            <div class="container">
                <div class="hero-banner--sm__content">
                    <h1>HYDROLOGY</h1>
                    <div style="display: block;">

                        <nav aria-label="breadcrumb" class="banner-breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/"><a>Home</a></router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/products"><a>Products</a></router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Hydrology</li>
                            </ol>
                        </nav>
                    </div>
                    <!-- Your tag goes here -->
                    <a class="product-brochure" href="/brochure/Catalog Product Brajakara 2023.pdf" >
                        <span class="dBtn bg">
                            <i class="ti-download"></i> Product Catalog
                        </span>
                    </a>

                </div>
            </div>
        </section>

        <section class="prod-section ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row">
                            <HydrologyList />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ContactUs />

    </div>
</template>
  
<script>
import HydrologyList from "@/components/HydrologyList.vue";
import ContactUs from "@/components/ContactUs"

export default {
    components: {
        HydrologyList,
        ContactUs
    },
};
</script>
  