<template>
  <div>
    <div class="background-div">
      <vue-particles class="particle-container"></vue-particles>
    </div>

    <section class="hero-banner--sm foreground-div" style="margin-top: 4em;">
      <div class="container">
        <div class="hero-banner--sm__content">
          <h1>WEATHER-VUE</h1>
          <nav aria-label="breadcrumb" class="banner-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/"><a>Home</a></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Weather-Vue</li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
    <div class="d-flex flex-row" v-if="ava_width > 768" style="margin-top: 6em">
      <marquee-text :repeat="text_repeat" :duration="custom_duration" :paused="isPaused"
        class="card pb-1 box custom-col-md" @mouseenter="isPaused = !isPaused" @mouseleave="isPaused = false">
        <span v-for="station in stations" :key="station[0].id" style="font-size: 1.35rem">
          <span style="color: #1b396b; font-weight: 500">
            {{ station[0].station_name }} &bull;
          </span>
          <span v-if="station[2]">
            <span v-for="(card, index) in [station[2]]" :key="index" class="flex mt-2" style="min-height: 12vh">
              <span v-for="(label, labelIndex) in card.running_label" :key="labelIndex" style="color: #1b396b">
                {{ label }}: {{ card.running_data[labelIndex] }}
                {{ card.running_symbol[labelIndex] }}
                <span v-if="labelIndex !== card.running_label.length - 1">
                  |
                </span>
              </span>
            </span>
          </span>
          <img :src="logo" style="width: 20px" class="mx-3 my-1" />
        </span>
      </marquee-text>
    </div>

    <section data-aos="zoom-in" data-aos-delay="200">
      <div>
        <v-map :zoom="5" :center="initialLocation" style="height: 66vh; width: auto">
          <v-icondefault></v-icondefault>
          <v-tilelayer url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"></v-tilelayer>
          <v-control class="example-custom-control m-2" style="border-radius: 10px; opacity: 100%; background: #fff;
          
          " :position="'bottomright'">
            <div class="py-2 px-2" style="font-weight: bold">
              <div><img src="@/assets/img/map/awlr25.svg" class="px-1 pb-1" />AWLR</div>
              <div><img src="@/assets/img/map/arr25.svg" class="px-1 py-1" />ARR</div>
              <div><img src="@/assets/img/map/aws25.svg" class="px-1 pt-1" />AWS</div>
            </div>
          </v-control>
          <v-marker-cluster :options="clusterOptions" @clusterclick="click()" @ready="ready">
            <span v-for="station in stations" :key="station.id">
              <v-marker v-if="station[0].station_type == 2" :lat-lng="latLng(station[0].latitude, station[0].longitude)">
                <v-icon>
                  <img src="@/assets/img/map/awlr25.svg" />
                </v-icon>
                <v-popup>

                  <div class="card text-white" style="max-width: 18rem; background: #065a4f;">
                    <div class="card-body">
                      <h5 class="card-title text-white">
                        <span style="font-weight: 900">{{
                          station[0].station_name
                        }}</span>

                      </h5>
                      <h6 class="card-title text-white" style="font-weight: 600">
                        {{ idDate(station[1].table.date) }}
                      </h6>
                      <ul v-for="card in [station[2]]" :key="card.id" class="flex mt-2">
                        <li v-for="(data, index) in marquee_g(
                          card.running_label,
                          card.running_data,
                          card.running_symbol,

                        )" :key="index">
                          <b>{{ data.sensor }}</b>: {{ data.data }}
                          {{ data.symbol }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-popup>
              </v-marker>
              <v-marker v-if="station[0].station_type == 1" :lat-lng="latLng(station[0].latitude, station[0].longitude)">
                <v-icon>
                  <img src="@/assets/img/map/arr25.svg" />
                </v-icon>
                <v-popup>
                  <div class="card text-white" style="max-width: 18rem; background: #065a4f;">
                    <div class="card-body">
                      <h5 class="card-title text-white">
                        <span style="font-weight: 900">{{
                          station[0].station_name
                        }}</span>

                      </h5>
                      <h6 class="card-title text-white" style="font-weight: 600">
                        {{ idDate(station[1].table.date) }}
                      </h6>
                      <ul v-for="card in [station[2]]" :key="card.id" class="flex mt-2">
                        <li v-for="(data, index) in marquee_g(
                          card.running_label,
                          card.running_data,
                          card.running_symbol,

                        )" :key="index">
                          <b>{{ data.sensor }}</b>: {{ data.data }}
                          {{ data.symbol }}
                        </li>
                      </ul>
                    </div>
                  </div>

                </v-popup>
              </v-marker>
              <v-marker v-if="station[0].station_type == 3" :lat-lng="latLng(station[0].latitude, station[0].longitude)">
                <v-icon>
                  <img src="@/assets/img/map/aws25.svg" />
                </v-icon>
                <v-popup>
                  <div class="card text-white" style="max-width: 18rem; background: #065a4f;">
                    <div class="card-body">
                      <h5 class="card-title text-white">
                        <span style="font-weight: 900">{{
                          station[0].station_name
                        }}</span>
                      </h5>
                      <h6 class="card-title text-white" style="font-weight: 600">
                        {{ idDate(station[1].table.date) }}
                      </h6>
                      <ul v-for="card in [station[2]]" :key="card.id" class="flex mt-2">
                        <li v-for="(data, index) in marquee_g(
                          card.running_label,
                          card.running_data,
                          card.running_symbol,

                        )" :key="index">
                          <b>{{ data.sensor }}</b>: {{ data.data }}
                          {{ data.symbol }}
                        </li>
                      </ul>
                    </div>
                  </div>

                </v-popup>
              </v-marker>
            </span>
          </v-marker-cluster>
          <v-control-scale position="bottomleft" :imperial="false" :metric="true"></v-control-scale>
          <v-control position="topright">
            <a href="http://weatherapp.blitztechnology.tech/login" class="btn btn-sm" role="button"
              style="background-color: #188692; color: #fff; font-weight: bold" target="_blank">LOGIN</a>
          </v-control>
        </v-map>
      </div>
    </section>
    <ContactUs />

  </div>
</template>

<script>
import * as Vue2Leaflet from "vue2-leaflet";
import { latLng } from "leaflet";
import Vue2LeafletMarkercluster from "@/components/Vue2LeafletMarkercluster";
import axios from "axios";
import MarqueeText from "@/assets/MarqueeText.vue";
import logo from "@/assets/logo-bt.svg";
import ContactUs from "@/components/ContactUs"

export default {
  components: {
    "v-map": Vue2Leaflet.LMap,
    "v-tilelayer": Vue2Leaflet.LTileLayer,
    "v-icondefault": Vue2Leaflet.LIconDefault,
    "v-marker": Vue2Leaflet.LMarker,
    "v-popup": Vue2Leaflet.LPopup,
    "v-marker-cluster": Vue2LeafletMarkercluster,
    "v-control-scale": Vue2Leaflet.LControlScale,
    "v-control": Vue2Leaflet.LControl,
    "v-icon": Vue2Leaflet.LIcon,
    MarqueeText, ContactUs
  },

  data() {
    return {
      clusterOptions: {},
      initialLocation: latLng(-1.230374, 120.498047),

      stations: [],
      isPaused: false,
      text_duration: null,
      text_repeat: null,
      logo,
      custom_duration: null,

      balai: null,
      ava_width: null,
      ava_height: null,
    };
  },

  methods: {
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    latLng: function (lat, lng) {
      return [lat, lng];
    },
    idDate(dateStr) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',  timeZone: 'Asia/Jakarta' };

      const indonesianDate = new Date(dateStr);
      return new Intl.DateTimeFormat('id-ID', options).format(indonesianDate);
    },
    marquee_f(a, b, c, d) {
      return a.map((card, i) => {
        return {
          sensor: card,
          data: b[i],
          symbol: c[i],
          siaga: d[i],
          role: "is_guess",
        };
      });
    },
    marquee_g(a, b, c) {
      return a.map((card, i) => {
        return {
          sensor: card,
          data: b[i],
          symbol: c[i],
          role: "is_guess",
        };
      });
    },
    async loadMarquee() {
      var st_name = [];
      var sensor = [];
      var st_name_length = null;
      var sensor_length = null;

      await axios
        .get(`${this.$baseURL}/home-data/non-auth/0`)
        .then((r) => {
          r.data.forEach((e) => {
            st_name.push(e[0].station_name.length);
            sensor.push(
              e[1].table.array_table_label.reduce((a, b) => a + b, 0).length
            );

            var x =
              e[0].station_name.length +
              e[1].table.array_table_label.reduce((a, b) => a + b, 0).length;

            e.duration = x * 510;
          });

          st_name_length = st_name.reduce((a, b) => a + b, 0);
          sensor_length = sensor.reduce((a, b) => a + b, 0);
          this.stations = r.data;

          this.custom_duration = (st_name_length + sensor_length) / 2;
          this.stations.slice(-1).pop().duration + 200;

          if (r.status == 200) {
            this.loading_i = false;
          }
        });

      let currentIndex = 0;

      const playSlide = () => {
        setTimeout(() => {
          currentIndex = (currentIndex + 1) % this.stations.length;

          if (this.$refs.carousel) {
            this.$refs.carousel.goToPage(currentIndex);
          }
          playSlide();
        }, this.stations[currentIndex].duration);
      };

      playSlide();
    }
  },
  created() {
    this.loadMarquee();
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 };
      });
    }, 5000);
    this.ava_width = screen.availWidth;
    this.ava_height = screen.availHeight;
  },
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

html,
body {
  height: 100%;
  margin: 0;
}

ul {
  list-style: none;
  display: table;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  padding-right: 1em;
}
</style>
