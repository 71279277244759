<template>
  <div :key="componentKey">
    <div class="background-div">
      <vue-particles class="particle-container"></vue-particles>
    </div>
    <section class="hero-banner--sm foreground-div pro-div">
      <div class="container">
        <div class="hero-banner--sm__content">
          <h1>{{ prodItem.title }}</h1>
          <nav aria-label="breadcrumb" class="banner-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/"><a>Home</a></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/products">Products</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{ prodItem.title }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </section>

    <section class="prod-section ">
      <div class="container py-3">
        <div class="row" data-aos="fade-down">
          <div class="col-lg-7">
            <img class="img-fluid p-5 main-img  mx-auto d-block rounded " v-if="prodItem.frontimg"
              :src="require(`@/assets/img/products/${prodItem.frontimg}`)" />
          </div>
          <div class="col-lg-5 text-justify" >
            <p class="section-intro__title pTitle" >{{
              prodItem.title }}</p>
            <p>{{ prodItem.detail }}</p>
            <a :href="`/brochure/${prodItem.file}.pdf`">
              <button type="button" class="btn btn-labeled mt-5 custom-button">
                <span class="btn-label"><i class="ti-download"></i></span>Product Brochure
              </button>
            </a>

          </div>
        </div>
        <div class="row" >
          <div class="col-lg-7" data-aos="fade-down"  data-aos-delay="100">
            <p class="section-intro__title " style="font-size: 1.5rem;">Specification</p>
            <table class="table">
              <tbody v-if="prodItem.specs">
                <tr v-for="(label, index) in prodItem.specs[0]" :key="index">
                  <td style="font-weight: bold;"> {{ label }}</td>
                  <td v-if="prodItem.specs[1][0][index].length == 1">
                    {{ prodItem.specs[1][0][index][0] }}
                  </td>
                  <td v-else>
                    <ul style="margin-left: 20px;" v-for="(item, index) in prodItem.specs[1][0][index]" :key="index">
                      <li>{{ item }}</li>
                    </ul>
                  </td>
                  <td v-if="prodItem.specs[1][1][index]">{{ prodItem.specs[1][1][index] }}</td>
                  <td v-if="prodItem.specs[1][2][index]">{{ prodItem.specs[1][2][index] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-5">
            <div data-aos="fade-down"  data-aos-delay="300">
              <p class="section-intro__title " style="font-size: 1.5rem;"  >Key Features</p>
              <ul style="margin-left: 20px;" v-for="(item, index) in prodItem.feature" :key="index">
                <li>{{ item }}</li>
              </ul>
            </div>
            <div data-aos="fade-down"  data-aos-delay="400">
              <p class="section-intro__title pt-5" style="font-size: 1.5rem;"  >Application</p>
              <ul style="margin-left: 20px;" v-for="(item, index) in prodItem.application" :key="index">
                <li>{{ item }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <p class="section-intro__title " style="font-size: 1.5rem;">Related Product</p>
          <!-- <a :href="`#/`">home</a> <br>
          <a :href="`#/product/2`" @click="changeRoute">testing</a> -->

          <div class="row">
            <div v-for="(list, index) in prodItem.related" :key="index" class="col-lg-3">
              <!-- <router-link @click="changeRoute" :to="`/product/${list[0]}`"> -->
              <a :href="`#/product/${list[0]}`" @click="changeRoute">
                <div class="card card-feature offer-single__content text-justify" style="cursor: pointer;"
                  data-aos="fade-right" :data-aos-delay="index * 150">
                  <div class="fimage mx-auto">
                    <img class="img-fluid pb-4 card-img-top related "
                      :src="require(`@/assets/img/products/${list[2]}`)" />
                  </div>
                  <h4 class="text-center">{{ list[1] }}</h4>

                </div>
              </a>
              <!-- </router-link> -->
            </div>
          </div>
        </div>

      </div>
    </section>
    <ContactUs />
  </div>
</template>

<script>
import jsonData from "@/json/data.json"; // Import your data source here
import ContactUs from "@/components/ContactUs"

export default {
  name: "ProductList",
  components: {
    ContactUs
  },
  data() {
    return {
      productTitle: null,
      prodItem: {},
      pdfUrl: '@/src/assets/brochure/testing.pdf',
      pdfFileName: 'testing.pdf',
      componentKey: 0,
    };
  },
  methods: {
    changeRoute() {
      setTimeout(() => {
        // Reload the page
        location.reload();
      }, 1); // 2000 milliseconds (2 seconds)
    },
  },
  mounted() {
    // Access the route parameter from the URL
    const productId = parseInt(this.$route.params.id);
    // const productId = 1; // Replace with the desired product ID

    // Find the product with the given ID across all arrays
    const allProducts = [
      ...jsonData[0].instrumentation,
      ...jsonData[0].hydrology,
      ...jsonData[0].software,
    ];

    // const productItem = allProducts.find(item => item.id === productId);
    const productItem = allProducts.find(item => item.id === parseInt(productId));

    this.prodItem = allProducts.find(item => item.id === parseInt(productId));
    if (productItem) {
      // If the item is found, set the title
      this.productTitle = productItem.title;
      // console.log(productItem);
    } else {
      // Handle the case where the item is not found
      this.productTitle = "Product Not Found";
    }
  },

}
</script>

<style scoped>
.main-img {
  max-height: 25rem;
  width: auto;
}

li {
  display: list-item;
  list-style-type: disc;
}

.table td,
.table th {
  padding: .35rem;
  color: grey;
}

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.btn {
  margin-bottom: 10px;
}

.card-feature:hover h4 {
  color: #00b398 !important;
  /* opacity: 0.5; */
}

.custom-button {
  background-color: #00B398;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s;
  /* Add a smooth transition */
}

.custom-button:hover {
  background-color: #008c7d;
  /* Change to a darker color on hover */
  color: white;

}

.related {
  max-height: 12rem;
  width: auto;
}

.pTitle {
  font-size: 2.2rem;
  line-height: 2.7rem;
}

@media (max-width: 767px) {
  .pTitle {
    font-size: 1.7rem;
    line-height: 2rem;
  }

  .main-img {
    max-height: 18rem;
  }
}
</style>

<style>
.banner-breadcrumb {
  text-align: center;
  /* Center horizontally */
}

.breadcrumb {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  list-style: none;
  /* Remove list-style bullet */
  padding: 0;
  margin: 0;
}

.breadcrumb-item {
  display: inline;
  /* Display items inline */
  margin: 0 5px;
  /* Add some spacing between items */
}
</style>