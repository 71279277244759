<template>
  <div class="pt-3">
    <div>
      <h2 class="section-intro__title py-3" data-aos="fade-down">Software Development</h2>
      <div class="row">
        <div v-for="(list, index) in software" :key="index" class="col-lg-4 offer-single">
          <router-link :to="`/product/${list.id} `">
            <div class="card offer-single__content text-justify" :data-target="`#slider-${1 + list.seq}`"
              style="cursor: pointer;" data-aos="fade-right" :data-aos-delay="list.seq * 200">
              <div class="fimage">
                <img class="img-fluid pb-4 card-img-top" :src="require(`@/assets/img/products/${list.frontimg}`)"
                  alt="" />
              </div>
              <h4>{{ list.title }}</h4>
              <p style="color: #797979;">{{ list.desc }}</p>
            </div>
          </router-link>
      
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonData from "@/json/data.json"; // Import your data source here


export default {
  name: "ProductList",
  data() {
    return {
      instrumentation: [],
      hydrology: [],
      software: []
    };
  },
  mounted() {

    if (jsonData && jsonData.length > 0) {

      // this.instrumentation = jsonData[0].instrumentation;
      // this.hydrology = jsonData[0].hydrology;
      this.software = jsonData[0].software;
    } else {
      console.error('JSON data is empty or not properly structured.');
    }
  }

};
</script>

<style scoped>
.fimage {
  margin-left: auto;
  margin-right: auto;
}

.card-img-top {
  height: 13rem;
  width: auto;
}

@media (min-width: 991px) {
  .offer-single {
    margin-bottom: 30px !important;
  }
}
</style>