<template>
  <header class="header_area">
    <div class="main_menu">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container box_1620">

          <router-link to="/" data-aos="fade-down-right">
            <a class=""><img src="../assets/logo.svg" alt="" height="45" /></a>
          </router-link>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

          <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
            <ul class="nav navbar-nav menu_nav justify-content-end">
              <li class="nav-item" data-aos="fade-down-left" data-aos-delay="50">
                <router-link to="/"><a class="nav-link">HOME</a></router-link>
              </li>
              <li class="nav-item" data-aos="fade-down-left" data-aos-delay="150">
                <div class="dropdown">
                  <router-link to="/products">
                    <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" aria-haspopup="true"
                      aria-expanded="false">
                      PRODUCTS
                    </a>
                  </router-link>
                  <div class="dropdown-menu bg-dark" aria-labelledby="dropdownMenuLink">
                    <router-link to="/instrumentation"><a class="dropdown-item">Instrumentation</a></router-link>
                    <router-link to="/hydrology"><a class="dropdown-item">Hydrology</a></router-link>
                    <router-link to="/software"><a class="dropdown-item">Software Development</a></router-link>
                  </div>
                </div>
              </li>

              <li class="nav-item" data-aos="fade-down-left" data-aos-delay="200">
                <router-link to="/b-weather"><a class="nav-link">WEATHER-VUE</a></router-link>
              </li>
              <li class="nav-item" data-aos="fade-down-left" data-aos-delay="200">
                <router-link to="/email"><a class="nav-link">CONTACT US</a></router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped>
a {
  font-weight: bold;
  color: #fff;
  text-decoration: none !important;
}

a.router-link-exact-active,
.nav-link:hover {
  color: #fff !important;
  opacity: 0.75;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
}
</style>