<template>
  <div class="home" style="overflow-x: hidden;">
    <main class="side-main">
      <div class="background-div">
        <vue-particles class="particle-container"></vue-particles>
      </div>
      <section class=" foreground-div">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 " data-aos="fade-down-right" data-aos-delay="400">
              <div class="hero-banner__img moving">
                <img class="img-fluid" src="@/assets/img/banner/banner.svg" alt="" />
              </div>
            </div>
            <div class="col-lg-5 ptMob-5 ">
              <div class="hero-banner__content">
                <h1 data-aos="fade-down-left" data-aos-delay="400">
                  Welcome to <br />
                  Brajakara Teknologi Media
                </h1>
                <p style="font-size: 20px" data-aos="fade-up" data-aos-delay="600">
                  <!-- Your trusted partner for advancing your technology -->
                  Your Leading Partner for Precision in Measurement, AI, and IoT Solutions.
                </p>
                <router-link to="/products" data-aos="fade-up" data-aos-delay="800"><a class="button bg">Get
                    Started</a></router-link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-margin ">
        <div class="container">
          <div class="section-intro text-center " style="max-width: 750px;">
            <h2 class="section-intro__title py-2" data-aos="fade-down">About Us</h2>
            <p class="section-intro__subtitle pb-3" data-aos="fade-up" data-aos-delay="200">
              Established in February 2020 in Bandung, PT. Brajakara Teknologi Media is dedicated to delivering
              comprehensive IT solutions, specializing in IoT (Internet of Things), AI (Artificial Intelligence), and
              Measurement Instrumentation. We proudly serve as your foremost partner for end-to-end business management
              solutions.
            </p>
          </div>

          <div class="row pt-2">
            <div class="col-lg-6">
              <div class="row offer-single-wrapper">
                <div class="col-lg-6 offer-single" data-aos="zoom-in">
                  <div class="card offer-single__content text-center">
                    <span class="offer-single__icon">
                      <i class="ti-bolt"></i>
                    </span>
                    <h4>Our Vision</h4>
                    <p>
                      Striving to be the most trusted and dedicated company in the development and research of essential
                      IT solutions for the betterment of humanity, with a special focus on Indonesia.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 offer-single" data-aos="zoom-in" data-aos-delay="100">
                  <div class="card offer-single__content text-center">
                    <span class="offer-single__icon">
                      <i class="ti-target"></i>
                    </span>
                    <h4>Our Mission</h4>
                    <p>
                      Advancing technology in Indonesia, globally competing for humanity's benefit, delivering top IT
                      solutions, fostering innovation, and prioritizing client success and trust.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5" data-aos="fade-left" data-aos-delay="300">
              <div class="offer-single__img">
                <img class="img-fluid" src="../assets/img/home/home1.gif" alt="" style="max-width: 80%;" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-margin pt-3">
        <div class="container">
          <div class="section-intro pb-60px text-center" data-aos="fade-down">
            <h2 class="section-intro__title" style="margin-bottom: 50px">
              <!-- Our Services -->
              Product Categories
            </h2>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="0">
                <router-link to="/instrumentation">
                  <div class="
                    card card-feature
                    text-center text-lg-left
                    mb-4 mb-lg-0
                  ">
                    <span class="card-feature__icon">
                      <ThemifyIcon /><i class="ti-ruler"></i>
                    </span>
                    <h3 class="card-feature__title">Instrumentation</h3>
                    <p class="card-feature__subtitle text-justify">
                      Precision instrumentation, including data loggers, sensors, weather
                      monitoring, rainfall, and water level solutions for all your measurement needs.
                    </p>
                  </div>
                </router-link>
              </div>
              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="150">
                <router-link to="/hydrology">

                  <div class="
                    card card-feature
                    text-center text-lg-left
                    mb-4 mb-lg-0
                  ">
                    <span class="card-feature__icon">
                      <img class="img-fluid" src="../assets/img/home/water.svg" style="max-height: 30px;" />

                    </span>
                    <h3 class="card-feature__title">Hydrology</h3>
                    <p class="card-feature__subtitle text-justify">
                      Encompassing water quality assessment and ultrasonic flow meter. Elevate your measurements with
                      our precision tools.
                    </p>
                  </div>
                </router-link>
              </div>
              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
                <router-link to="/software">
                  <div class="
                    card card-feature
                    text-center text-lg-left
                    mb-4 mb-lg-0
                  ">
                    <span class="card-feature__icon">
                      <i class="ti-package"></i>
                    </span>
                    <h3 class="card-feature__title">Software Development</h3>
                    <p class="card-feature__subtitle text-justify">
                      Explore our expert software development services, where we transform ideas into robust digital
                      solutions.
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-padding-small bg-magnolia">

        <div class="container">
          <div class="row align-items-center pt-xl-3 pb-xl-5">
            <div class="col-lg-6">
              <div class="solution__content">
                <h2 data-aos="fade-down">Data Logger</h2>
                <p class="text-justify" data-aos="fade-up" data-aos-delay="100">
                  <strong> Data loggers </strong>play a pivotal role in data acquisition systems by
                  seamlessly interfacing with a diverse range of sensors. They excel at executing programmed calculations,
                  converting data into different units, and securely storing information in memory. Beyond these
                  capabilities, data loggers can facilitate data transmission for analysis, sharing, and reporting, while
                  also exerting control over external devices when necessary.
                </p>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-left" data-aos-delay="100">
              <div class="solution__img text-center text-lg-left mb-4 mb-lg-0">
                <img class="img-fluid" src="../assets/img/home/BT_Logger_v2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-padding-small">
        <div class="container">
          <div class="row align-items-center pt-xl-3 pb-xl-5">
            <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
              <div class="solution__img text-center text-lg-left mb-4 mb-lg-0">
                <img class="img-fluid" src="../assets/img/home/data_monitoring.png" alt="" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="solution__content">
                <h2 data-aos="fade-down">Data Monitoring</h2>
                <p class="text-justify" data-aos="fade-up" data-aos-delay="100">
                  Our <strong>Data Monitoring</strong> service is a web-based application that offers
                  convenient access to real-time data from all monitoring stations via a cloud-based data hosting
                  platform. We ensure the security of your data by providing a user-friendly web page where you can view
                  information in table format, track the last 24 hours of data activity, and access station-specific maps.
                  Additionally, users have the flexibility to download all available data. For those who prefer raw data
                  for customized analysis, our service can readily provide the necessary information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurPartner />
      <ContactUs />

    </main>
  </div>
</template>

<script>
import ThemifyIcon from "vue-themify-icons";
import OurPartner from "@/components/OurPartner"
import ContactUs from "@/components/ContactUs"

export default {
  name: "Home",
  components: {
    ThemifyIcon,
    OurPartner,
    ContactUs
  },
};
</script>

<style scoped>
a {
  font-weight: bold;
  color: #3a414e;
}

a:hover {
  color: #fff;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}


.moving img {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-15px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-15px);
  }
}

.card-feature:hover .card-feature__title {
  color: #00b398 !important;
  /* opacity: 0.5; */
}

.card-feature__subtitle {
  color: #797979;
  font-weight: normal
}
</style>

<style>
.particle-container {
  background: #065a4f;
  position: relative;
  z-index: 0;
  height: 650px;

}

.background-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  /* Set a negative z-index to place it behind the foreground-div */
}

.foreground-div {
  width: 100%;
  /* height: 75px; */
  position: relative;
  margin-top: 150px;
  z-index: 2;
  /* Ensure the foreground is in front of particles */
  /* top: -50px; */
  /* padding: 0px 0; */
  padding-bottom: 10em;
}

.ptMob-5 {
  padding-top: 3rem;
}

h1 {
  font-size: 40px !important
}

@media (max-width: 767px) {
  .ptMob-5 {
    padding-top: 0rem;
  }

  .foreground-div {

    margin-top: 100px;
    padding: 0em;
  }

  h1 {
    font-size: 35px !important
  }

  .hero-banner__content p {
    margin-bottom: 10px !important;
  }
}
</style>