<template>
  <div>
    <div class="background-div">
      <vue-particles class="particle-container"></vue-particles>
    </div>
    <section class="hero-banner--sm foreground-div pro-div">
      <div class="container">
        <div class="hero-banner--sm__content">
          <h1>PRODUCTS</h1>
          <div style="display: block;">
            <nav aria-label="breadcrumb" class="banner-breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/"><a>Home</a></router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Products</li>
              </ol>
            </nav>
          </div>


          <!-- Your tag goes here -->
          
          <a class="product-brochure" href="/brochure/Catalog Product Brajakara 2023.pdf" >
            <span class="dBtn bg">
              <i class="ti-download"></i> Product Catalog
            </span>
          </a>

        </div>
      </div>
    </section>

    <section class="prod-section ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <ProductList />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ContactUs />

  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import ContactUs from "@/components/ContactUs"

export default {
  components: {
    ProductList,
    ContactUs
  },
};
</script>

<style >
.breadcrumb {
  margin-bottom: 1rem !important;


}

.product-brochure {
  /* display: block; */
  /* Ensure it's displayed as a block element */
  clear: both;
  /* Force a line break, clearing any floated elements */
}

.dBtn {
  display: inline-block;
  border: 1px solid transparent;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 12px;
  border-radius: 10px;
  background-color: #fff;
  color: #21146a;
  color: #3a414e;
  transition: all .4s ease;
  display: inline-block;
  /* Ensure it's displayed as a block-level element */
  text-align: center;
  /* Center align the contents */
}

.dBtn i {
  vertical-align: middle;
  /* Vertically align the icon with the text */
  margin-right: 5px;
  /* Add some space between the icon and text */
}

.dBtn:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
</style>

<style>
.pro-div {
  margin-top: 4rem;
}

.prod-section {
  margin-top: 6rem
}


@media (max-width: 767px) {
  .pro-div {
    margin-top: 12rem;
  }

  .prod-section {
    margin-top: 9rem
  }
}
</style>