<template>
  <body>
    <TopFixed />
    <Header />
    <router-view />
    <Footer />
  </body>
</template>


<script>
import TopFixed from "@/components/TopFixed.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    TopFixed,
    Header,
    Footer,
  },
};
</script>

<style>

</style>