<template>
    <section style="padding: 20px 0 50px 0">
        <div class="fixed-button-container">
            <a href="whatsapp://send?text=&phone=+6282120339983">

                <img src="../assets/img/WhatsApp.svg">
                <!-- Replace 'your-image-url.png' with your image URL -->

            </a>
        </div>
        <div class="container">
            <div class="section-intro text-center" data-aos="zoom-in">
                <h2 class="section-intro__title" style="margin-bottom: 20px">Contact Us</h2>
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-lg-4" data-aos="zoom-in" data-aos-delay="100">
                        <a href="https://maps.app.goo.gl/L3mwNjZginE2LxFc8" target="_blank">
                            <div class="card card-feature text-center mb-4 mb-lg-0"
                                style="display: flex; flex-direction: column; align-items: center;">
                                <span class="card-feature__icon">
                                    <i class="ti-home"></i>
                                </span>
                                <h4 class="card-feature__title" style="font-size: 20px">Bandung - Jawa Barat</h4>
                                <h6 class="card-feature__subtitle">
                                    Jl. Kuningan Raya No.101, Antapani
                                </h6>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4" data-aos="zoom-in" data-aos-delay="300">
                        <a href="mailto:info@blitztechnology.tech">
                            <div class="card card-feature text-center mb-4 mb-lg-0">
                                <span class="card-feature__icon">
                                    <i class="ti-email"></i>
                                </span>
                                <h4 class="card-feature__title" style="font-size: 20px">info@blitztechnology.tech</h4>

                                <div class="row px-2 d-flex justify-content-center socmed card-feature__subtitle">
                                    <a href="https://id.linkedin.com/company/pt-brajakara-teknologi-media?trk=companies_directory"
                                        target="_blank">
                                        <i class="ti-linkedin px-2"></i>
                                    </a>
                                    <a href="https://www.instagram.com/brajakarateknologi/" target="_blank">
                                        <i class="ti-instagram px-2"></i>
                                    </a>
                                    <a href="https://www.facebook.com/profile.php?id=100087614298698" target="_blank">
                                        <i class="ti-facebook px-2"></i>
                                    </a>

                                </div>


                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4" data-aos="zoom-in" data-aos-delay="200">
                        <a href="whatsapp://send?text=&phone=+6282120339983">
                            <div class="card card-feature text-center mb-4 mb-lg-0 ">
                                <span class="card-feature__icon">
                                    <i class="ti-headphone"></i>
                                </span>
                                <h4 class="card-feature__title" style="font-size: 20px">0821 2033 9983</h4>

                                <h6 class="card-feature__subtitle">Mon to Fri 9am to 5pm</h6>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "ContactUs",

};
</script>

<style scoped>
.fixed-button-container {
    position: fixed;
    bottom: 20px;
    /* Adjust the distance from the bottom as needed */
    right: 20px;
    /* Adjust the distance from the right as needed */
    width: 3rem;
    z-index: 5;
}

a {
    font-weight: bold;
    color: #3a414e;
}

a:hover {
    color: #fff;
}

.socmed {
    margin-top: -5px;
}
.socmed a:hover {
    color: #00b398;
}

.card:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-15px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-15px);
    }
}

.card-feature:hover .card-feature__title {
    color: #00b398 !important;
    /* opacity: 0.5; */
}
</style>