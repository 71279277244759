<template>
    <section class="section section-default mt-none mb-none">
        <div class="container">
            <h2 class="mb-sm text-center pb-4" data-aos="fade-down">Our <strong>Partners</strong></h2>
            <strong>

                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in clients" :key="index">
                        <div class="square-holder" data-aos="fade-down">
                            <img :src="item.path" />
                        </div>
                    </div>
                </div>
            </strong>
        </div>
    </section>
</template>

<script>

export default {
    name: "OurPartner",
    data() {
        return {
            clients: [
                { path: require("@/assets/img/clients-logo/BBWSS_8.png") },
                { path: require("@/assets/img/clients-logo/bmkg.png") },
                { path: require("@/assets/img/clients-logo/BWS_Bali_Penida.png") },
                { path: require("@/assets/img/clients-logo/gmf aeroasia.png") },
                { path: require("@/assets/img/clients-logo/inovastek.png") },
                { path: require("@/assets/img/clients-logo/itb.png") },
                { path: require("@/assets/img/clients-logo/pupr.png") },
                { path: require("@/assets/img/clients-logo/dmd.svg") },
            ],
        };
    },
};
</script>

<style scoped>
.square-holder {
    padding: 15px;
    /* border: 1px solid #cecece; */
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    /* background-color: #f1f1f1; */
    height: 150px
}

.square-holder img {
    max-width: 100%;
    max-height: 120px;
    /* filter: grayscale(100%);
    transition: all 0.3s; */
}

.square-holder:hover img {
    filter: none;
}

@media (max-width: 767px) {
    .square-holder {
        padding: 15px;
        /* border: 1px solid #cecece; */
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        /* background-color: #f1f1f1; */
        height: 100px
    }

    .square-holder img {
        max-height: 70px;
    }
}
</style>
