<template>
  <footer class="footer-area">
    <div class="container">
      <p class="footer-text">
        Copyright &copy; {{ date }} PT Brajakara Teknologi Media
      </p>
    </div>

  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      date: "",
    };
  },
  methods: {
    co_date() {
      this.date = new Date().getFullYear();

      return this.date;
    },
  },
  mounted() {
    this.co_date();
  },
};
</script>

<style>
/* .background-div {
  width: 100%;
  height: 200px;
} */

/* Styling for the background div */
.background-div {
  width: 100%;

}

/* Styling for the Vue Particles component */
.particle-container {
  height: 100px;
  position: relative;
  z-index: 1;
  background-color: #065a4f;
}

/* Styling for the foreground div */
.foreground-div {
  width: 100%;
  /* height: 75px; */
  position: relative;
  /* margin: -60px 0 auto; */
  z-index: 2;
  /* Ensure the foreground is in front of particles */
  /* top: -50px; */
  padding: 0px 0;
}

.footer-text {
  color: whitesmoke;
  padding-top: 0px;
}

@media (max-width: 767px) {
  .footer-area {
    padding: 10px 0px !important;
  }

  .footer-text {
    margin-top: 15px;
  }
}
</style>