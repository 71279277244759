import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Products from '@/views/Products.vue'
import ProductDetail from '@/views/ProductDetail.vue'
import B_Weather from '@/views/B_Weather.vue'
import NotFound from '@/views/NotFound.vue'
import Email from '@/views/Email.vue'
import Instrumentation from '@/views/Instrumentation.vue'
import Hydrology from '@/views/Hydrology.vue'
import Software from '@/views/Software.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/products',
        name: 'Products',
        component: Products
    },
    {
        path: '/instrumentation',
        name: 'Instrumentation',
        component: Instrumentation
    },
    {
        path: '/hydrology',
        name: 'Hydrology',
        component: Hydrology
    },
    {
        path: '/software',
        name: 'Software',
        component: Software
    },
    {
        path: '/product/:id',
        name: 'ProductDetail',
        component: ProductDetail
    },
    {
        path: '/b-weather',
        name: 'B_Weather',
        component: B_Weather
    },
    {
        path: '/email',
        name: 'Email',
        component: Email
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }
]

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router